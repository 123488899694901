// import logo from './logo.svg';
import './App.css';
import Nav from './components/Nav';
import Home from './components/Home';
import Tweet from './components/Tweet';
import Animals from './components/Animals';
import Animal from './components/Animal';
import InteractiveMap from './components/InteractiveMap'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route path="/" exact element={<InteractiveMap/>} />
            {/* <Route path="/tweets" exact element={<Tweet/>} />
            <Route path="/animals" exact element={<Animals/>} />
            <Route path="/animal/:name" exact element={<Animal/>} /> */}
            <Route path='/map' exact element={<InteractiveMap />} />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
