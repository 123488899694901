import React from "react";
import { Link } from "react-router-dom";



function Nav() {

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <a className="navbar-brand" href="#">
        MedievalDex
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarText"
        aria-controls="navbarText"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarText">
        <ul className="navbar-nav mr-auto">
        <li className="nav-item active">
            <a className="nav-link" href="/">
              Map <span className="sr-only">(current)</span>
            </a>
          </li>
          {/* <li className="nav-item active">
            <a className="nav-link" href="/">
              Home <span className="sr-only">(current)</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/tweets">
              Tweets
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/animals">
              Animals
            </a>
          </li> */}
        </ul>
        {/* <form className="form-inline">
            <input className="form-control bg-dark" type="search" placeholder="Search animals & items" aria-label="Search" flex-grow="1" width="100%"/>
        </form> */}
      </div>
    </nav>
  );
}

export default Nav;
