import React, { useRef, useEffect, useState } from 'react';
import oxbow_map from './../images/oxbwow_map_town_names.webp';
import cave from './../images/cave_pixelart.webp';
import clay from './../images/clay_pixelart.webp';
import ruin from './../images/ruin_pixelart.webp';
import boar from './../images/boar_silhouette.webp';
import deer from './../images/deer_silhouette.webp';
import wisent from './../images/wisent_silhouette.webp';
import bear from './../images/bear_silhouette.webp';
import wolf from './../images/wolf_silhouette.webp';
import rabbit from './../images/rabbit_silhouette.webp';
import lynx from './../images/Lynx_silhouette.webp';


import './InteractiveMap.css';

import DocumentMeta from 'react-document-meta';

const meta = {
	title: "MedievalDex Oxbow Map | Medieval Dynasty",
	description: "Here is an interactive map of the Oxbow from Medieval Dynasty. The map shows the positions of of structures like caves and ruins as well as resources like clay and the different animals.",
	canonical: "https://medievaldex.com/map",
	meta: {
		charset: "utf-8",
		name: {
			keywords: "Medieval Dynasty,MedievalDex,medievaldex,medieval dynasty map,medieval dynasty oxbow,medieval dynasty oxbow map,medieval dynasty cave,medieval dynasty oxbow cave,medieval dynasty interactive map"
		}
	}
}

const position_icon_middle_x = 25;
const position_icon_middle_y = 25;

const position_silhouette_middle_x = 15;
const position_silhouette_middle_y = 15;


	
var showCave = false;
var showClay = false;
var showRuin = false;
var showBoar = false;
var showDeer = false;
var showWisent = false;
var showBear = false;
var showWolf = false;
var showRabbit = false;
var showLynx = false;


async function add_element(x, y, name, category) {


	var canvas = document.getElementById("mapCanvas");
	var ctx = canvas.getContext("2d");
	const image = new Image();
	image.width = (image.width/2);
	switch(category) {
		case "Animal":
			switch(name) {
				case "Boar":
					if(showBoar) image.src = boar;
					break;
				case "Deer":
					if(showDeer) image.src = deer;
					break;
				case "Wisent":
					if(showWisent) image.src = wisent;
					break;
				case "Bear":
					if(showBear) image.src = bear;
					break;
				case "Wolf":
					if(showWolf) image.src = wolf;
					break;
				case "Rabbit":
					if(showRabbit) image.src = rabbit;
					break;
				case "Lynx":
					if(showLynx) image.src = lynx;
					break;
				default:
					break;
			}
			x -= position_silhouette_middle_x;
			y -= position_silhouette_middle_y;
			break;
		case "Structure":
			switch(name) {
				case "Cave":
					if(showCave) image.src = cave;
					break;
				case "Ruin":
					if (showRuin) image.src = ruin;
					break;
				default:
					break;
			}
			x -= position_icon_middle_x;
			y -= position_icon_middle_y;
			break;
		case "Resource":
			switch(name) {
				case "Clay":
					if(showClay) image.src = clay;
					break;
				default:
					break;
			}
			x -= position_icon_middle_x;
			y -= position_icon_middle_y;
			break;
		default:
			break;
	}

	image.onload = () => {
		ctx.drawImage(image, x,y);
	};

}




const InteractiveMap = () => {
	document.body.style.overflow = "hidden";
	const myCanvas = useRef();
	const width = 1000;
	const height = 1000;

    const [positions, setPositions] = useState([]);

    const fetchPositions = async() => {
        const data = await fetch('https://api.medievaldex.com/map');
		console.log("data: ");
		console.log(data);

        const pos = await data.json()
		.then( (json) => {
			console.log("json: ");
			console.log(json);
			setPositions(json); 
		});
    };

	

	useEffect(() => {
        fetchPositions();
		
	  }, []);
	
	useEffect(() => {
		const context = myCanvas.current.getContext("2d");
		const image = new Image();
		image.src = oxbow_map;

		// the background image needs to load first
		// after that the image can be drawn so it will not be left out
		image.onload = () => {
			context.drawImage(image, 0, 0, width, height);
			positions.map(position => ( 
				add_element(position.X, position.Y, position.Name, position.Category)
			   ));
		};
	}, [fetchPositions]);

	window.addEventListener('wheel',function(event){
		console.log("event: ", event.deltaY);
		// if scroll wheel up
		if (event.deltaY == -100) {
			
		} // if scroll wheel down
		else if (event.deltaY == 100) {

		}
		event.preventDefault();
	}, {passive:false});
		
	function showCave_Changed() {
		showCave = !showCave;
		fetchPositions();
	}

	function showClay_Changed() {
		showClay = !showClay;
		fetchPositions();
	}
	
	function showRuin_Changed() {
		showRuin = !showRuin;
		fetchPositions();
	}

	function showBoar_Changed() {
		showBoar = !showBoar;
		fetchPositions();
	}

	function showDeer_Changed() {
		showDeer = !showDeer;
		fetchPositions();
	}

	function showWisent_Changed() {
		showWisent = !showWisent;
		fetchPositions();
	}

	function showBear_Changed() {
		showBear = !showBear;
		fetchPositions();
	}

	function showWolf_Changed() {
		showWolf = !showWolf;
		fetchPositions();
	}

	function showRabbit_Changed(){
		showRabbit = !showRabbit;
		fetchPositions();
	}

	function showLynx_Changed(){
		showLynx = !showLynx;
		fetchPositions();
	}
	
	const mainContainerStyle = {
		display: 'flex',
	};

	return (
		<div>
			<DocumentMeta {...meta} />
			<div className='category-selection'>
				<div className='category-list'>
					<div>
					<h2>Structures</h2>
					</div>
					<ul className='category-group'>
						<div className='single-category'>
							<switch>
								<div className='category-label'>
									Cave
								</div>
								<input type="checkbox" className='category-checkbox' defaultChecked={showCave} contentEditable="true" onChange={showCave_Changed}/>
							</switch>
						</div>
						<div className='single-category'>
							<switch>
								<div className='category-label'>
									Ruin
								</div>
								<input type="checkbox" className='category-checkbox' defaultChecked={showRuin} contentEditable="true" onChange={showRuin_Changed}/>
							</switch>
						</div>
					</ul>
					
					<div>
					<h2>Resources</h2>
					</div>
					<ul className='category-group'>
						<div className='single-category'> 
							<switch>
								<div className='category-label'>
									Clay
								</div>
								<input type="checkbox" className='category-checkbox' defaultChecked={showClay} contentEditable="true" onChange={showClay_Changed}/>
							</switch>
						</div>
					</ul>
					<div>
					<h2>Animals</h2>
					</div>
					<ul className='category-group'>
						<div className='single-category'>
							<switch>
								<div className='category-label'>
									Boar
								</div>
								<input type="checkbox" className='category-checkbox' defaultChecked={showBoar} contentEditable="true" onChange={showBoar_Changed}/>
							</switch>
						</div>
						<div className='single-category'>
							<switch>
								<div className='category-label'>
									Deer
								</div>
								<input type="checkbox" className='category-checkbox' defaultChecked={showDeer} contentEditable="true" onChange={showDeer_Changed}/>
							</switch>
						</div>
						<div className='single-category'>
							<switch>
								<div className='category-label'>
									Wisent
								</div>
								<input type="checkbox" className='category-checkbox' defaultChecked={showWisent} contentEditable="true" onChange={showWisent_Changed}/>
							</switch>
						</div>
						<div className='single-category'>
							<switch>
								<div className='category-label'>
									Bear
								</div>
								<input type="checkbox" className='category-checkbox' defaultChecked={showBear} contentEditable="true" onChange={showBear_Changed}/>
							</switch>
						</div>
						<div className='single-category'>
							<switch>
								<div className='category-label'>
									Wolf
								</div>
								<input type="checkbox" className='category-checkbox' defaultChecked={showWolf} contentEditable="true" onChange={showWolf_Changed}/>
							</switch>
						</div>
						<div className='single-category'>
							<switch>
								<div className='category-label'>
									Rabbit
								</div>
								<input type="checkbox" className='category-checkbox' defaultChecked={showRabbit} contentEditable="true" onChange={showRabbit_Changed}/>
							</switch>
						</div>
						<div className='single-category'>
							<switch>
								<div className='category-label'>
									Lynx
								</div>
								<input type="checkbox" className='category-checkbox' defaultChecked={showLynx} contentEditable="true" onChange={showLynx_Changed}/>
							</switch>
						</div>
					</ul>
				</div>
			</div>
		
			<div className='main-content'>
				<canvas
					ref={myCanvas}
					width={width}
					height={height}
					id='mapCanvas'
				/>
			</div>

			<div className='ad-content'>
			</div>
			<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8824509877994453"
     		crossorigin="anonymous"></script>
		</div>
		
	)
};

export default InteractiveMap;